import { type } from "os";

const ADD_POST = 'ADD-POST';
const UPDATE_NEW_POST_TEXT = 'UPDATE-NEW-POST-TEXT';

type PostsType = {
    id: number 
    message: string
}
let initialState = {
    posts: [
        {id: 1, message: 'Hi, how are you?'},
        {id: 2, message: 'It\'s my first post'},
        {id: 3, message: 'Blabla'},
        {id: 4, message: 'Dada'}
    ] as Array<PostsType>
    //,
    //newMessageBody: ''
};
export type InitialStateType = typeof initialState;

const messageReducer = (state = initialState, action: any): InitialStateType => {

    switch(action.type) {
        
        //case UPDATE_NEW_POST_TEXT:
        //    return {
        //        ...state,
        //        newMessageBody: action.text
        //    };
        case ADD_POST:
            return {
                ...state,
                //newMessageBody: '',
                posts: [...state.posts, {id: 6, message: action.newMessageBody}]
            };
        default:
            return state;
    }
}
type AddMessageActionCreatorType = {
    type: typeof ADD_POST
    newMessageBody: string
}
export const addMessageActionCreator = (newMessageBody: string): AddMessageActionCreatorType => ({ type: ADD_POST, newMessageBody })
//export const updateNewMessageTextActionCreator = (text) => ({type: UPDATE_NEW_POST_TEXT, text })

export default messageReducer;