import React from 'react';
import LoginBar from './LoginBar';
import { connect } from "react-redux";
import { getAuthUserPhotos, getAuthUserData, setAuthToken, logoutToSamurai } from "../../redux/auth-reducer";

class LoginBarContainer extends React.Component { 

	componentDidMount() {
        
		let token = this.props.token;
		this.props.setAuthToken(token);
		if (token) {
			this.props.getAuthUserData(token);
		}
        const authorizedUserId = this.props.authorizedUserId;
        if (authorizedUserId) {
            this.props.getAuthUserPhotos(authorizedUserId);
        }
	}
	render() {
		return <LoginBar {...this.props} />;
	}
}
const mapStateToProps = (state) => ({

	isAuth: state.auth.isAuth,
	name: state.auth.name,
	userpic: state.auth.userpic,
	photos: state.auth.photos,
	nameLogin: state.auth.login,
    authorizedUserId: state.auth.userId

});
export default connect(mapStateToProps, { getAuthUserPhotos, getAuthUserData, setAuthToken, logoutToSamurai }) (LoginBarContainer);
