import React from 'react';
import ProfileInfo from "./ProfileInfo/ProfileInfo";

const Profile = (props) => {
    //alert(props.isAuth)
    return (
        <>
            <ProfileInfo 
                savePhoto={props.savePhoto}  
                isOwner={props.isOwner} 
                profile={props.profile} 
                status={props.status} 
                updateStatus={props.updateStatus}
                saveProfile={props.saveProfile} 
            />
            {/*<MyPostsContainer />*/}
        </>
    )
}
export default Profile;