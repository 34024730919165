import React from 'react';
import s from './Services.module.css';
import ServiceItem from './Service/ServiceItem';

const Services = React.memo(props => {

    let servicesElements = props.services.map((service) => (
        <ServiceItem
            key={service.id}
            id={service.id}
            name={service.name}
            description={ service.params.seo_description ? service.params.seo_description : service.params.description }
            price={service.params.price}
            status={service.status}
            //url={service.url}
            icon={service.params.iconservice}
        />
    ));
   return (
      <section className="container">
         <div className={`${s.home_price} row`}>{servicesElements}</div>
      </section>
   );
});

export default Services;
