import { getAuthUserDataSamurai } from "./auth-reducer";

const SET_INITIALIZED_SUCCSESS = 'SET_INITIALIZED_SUCCSESS';

type InitialStateType = {
    initialized: boolean // булев тип - false/true
}
let initialState: InitialStateType = {
    initialized: false,
    //globalError: null
};

const appReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_INITIALIZED_SUCCSESS:
            return {
                ...state,
                initialized: true
            }
        default:
            return state;
    }
};

type InitializedSuccessType = {
    type: typeof SET_INITIALIZED_SUCCSESS
}
export const initializedSuccess = (): InitializedSuccessType => ({ type: SET_INITIALIZED_SUCCSESS});

export const initializeApp = () => (dispatch: any) => {

	let promise = dispatch( getAuthUserDataSamurai() );
	Promise.all([promise]).then( () => {
		dispatch( initializedSuccess() );
	}); 
	 

};

export default appReducer;