import React from "react";
import Services from "./Services";
import { connect } from 'react-redux';
//import axios, * as others from "axios";
import Preloader from "../common/Preloader/Preloader";
import { requestServices } from "../../redux/services-reducer";
//import { pagesAPI } from "./../../api/api";

class ServicesContainer extends React.Component {
    
    componentDidMount() {
        this.props.requestServices();
        
        //this.props.toggleIsFetching(true);
        //axios.get(`https://www.vmodern.ru/api.php/site.page.getList?domain_id=1&params=1`,
        //   {headers: {'Authorization': 'Bearer f507aae1952a285c4d712975c36cc8a7'} 
        //})
        /*pagesAPI.getServices().then(response => {
            this.props.toggleIsFetching(false);
            this.props.setServices(response);         
        });*/
    }
    
    render() {
        return <>    
            { this.props.isFetching ? <Preloader /> : null }
            <Services {...this.props} />
            { this.props.errorMessage &&
                <section className="container"><h3 className="error"> { this.props.errorMessage } </h3></section> }
        </> 
    }
}

let mapStateToProps = (state) => {
   return {
      services: state.servicesPage.services,
      errorMessage: state.servicesPage.errorMessage,
      isFetching: state.usersPage.isFetching
   }
}

const ServicesPage = connect(mapStateToProps, { requestServices })(ServicesContainer);

export default ServicesPage;
