import { stopSubmit } from "redux-form";
import { authAPI, profileAPI, securityAPI } from "../api/api";

const SET_USER_DATA = 'SET_USER_DATA';
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
const SET_AUTH_USER_PHOTOS = 'SET_AUTH_USER_PHOTOS';
const SET_CAPTCHA_URL = 'SET_CAPTCHA_URL';

/* export type InitialStateType2 = {
    userId: number | null,
    name: string | null,
    email: string | null,
    userpic: string | null,
	photos: any,
    isAuth: boolean,
    token: string | null,
    captchaUrl: string | null
} */
let initialState = {
    userId: null as number | null,
    name: null as string | null,
    email: null as string | null,
    userpic: null,
	photos: [] as any,
    isAuth: false as boolean,
    token: null as string | null,
    captchaUrl: null as string | null
};
export type InitialStateType = typeof initialState;

const authReducer = (state = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case SET_USER_DATA:
        case SET_CAPTCHA_URL:
            return {
                ...state,
                ...action.payload
            }
        case SET_AUTH_USER_PHOTOS:
            return {
                ...state,
                photos: action.photos
            }
        case SET_AUTH_TOKEN:
            return {...state, token: action.token}    
        default:
            return state;
    }
};



// для Webasyst
export const setAuthUserData = (name: string, userpic: string) => ({ type: SET_USER_DATA, payload: {name, userpic} });
export const setAuthToken = (token: string) => ({ type: SET_AUTH_TOKEN, token });
export const getAuthUserData = (token: string) => (dispatch: any) => {
	authAPI.me(token).then(response => {
		if (response.statusText === 'OK') { 
			let {name, userpic} = response.data;
			dispatch(setAuthUserData(name, userpic));

		}
	});
};

type SetAuthUserDataSamuraiActionPayloadType = {
    userId: number
    email: string | null
    login: string | null
    isAuth: boolean
}
type SetAuthUserDataSamuraiActionType = {
    type: typeof SET_USER_DATA
    payload: SetAuthUserDataSamuraiActionPayloadType
}
type SetCaptchaUrlActionType = {
    type: typeof SET_CAPTCHA_URL 
    payload: { captchaUrl: string }
}

// для Samurai
export const setAuthUserDataSamurai = 
(userId: number, email: string | null, login: string | null, isAuth: boolean): SetAuthUserDataSamuraiActionType => ({ 
    type: SET_USER_DATA, 
    payload: {userId, email, login, isAuth} 
});
export const setAuthUserPhotos = (photos: any) => ({ type: SET_AUTH_USER_PHOTOS, photos });
export const setCaptchaUrl = (captchaUrl: string): SetCaptchaUrlActionType => ({ type: SET_CAPTCHA_URL, payload: { captchaUrl }});

export const getAuthUserDataSamurai = () => async (dispatch: any) => {
	const response = await authAPI.meSamurai();
    if (response.data.resultCode === 0) {
        let { id, email, login } = response.data.data;
        dispatch(setAuthUserDataSamurai(id, email, login, true));
    }
};
export const getAuthUserPhotos = (userId: number) => async (dispatch: any) => {
	const response = await profileAPI.getProfile(userId);
    dispatch(setAuthUserPhotos(response.data.photos));
};
export const loginToSamurai = (email: string, password: string, rememberMe: boolean, captcha: any) => async (dispatch: any) => {
    const response = await authAPI.login(email, password, rememberMe, captcha);
    if (response.data.resultCode === 0) {    
        dispatch(getAuthUserDataSamurai());
    } else {
        if (response.data.resultCode === 10) {
            dispatch(getCaptchaUrl());
        }
        let message = response.data.messages.length > 0 ? response.data.messages[0] : 'Some error';
        dispatch( stopSubmit("Login", { _error: message}) );
    }
};
export const logoutToSamurai = () => (dispatch: any) => {
	authAPI.logout().then(response => {
		if (response.data.resultCode === 0) { 
			dispatch(setAuthUserDataSamurai(0, null, null, false));
		}
	});
};

export const getCaptchaUrl = () => async (dispatch: any) => {
	const response = await securityAPI.getCaptchaUrl();
    const captchaUrl = response.data.url;
    dispatch(setCaptchaUrl(captchaUrl));
};


export default authReducer;