import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Feather from "react-icons/fi";
import { NavBarData } from "./NavbarData";
import SubMenu from "./SubMenu";
import NavMenu from "./NavMenu";
import Icon from '../common/IconsSvg/IconsSvg';
import LoginBarContainer from "../LoginBar/LoginBarContainer";


const Nav = styled.div`
    background: #141414;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;

    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;

    &.hide {
        top: -6rem;
    }
`;

const NavIcon = styled(Link)`
    margin-left: 1rem;
    font-size: 1.625em;
    height: 80px;
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    color: #fff;
`;
const NavLogo = styled.div`
    display: flex; 
    gap: 2rem;
    align-items: center;
    color: #fff;
`;

const Logo = styled(Link)`
    
    color: #fff;
`;

//const SidebarNav = styled.nav.attrs(props => ({ $sidebar: props.$sidebar ? '0' : '-100%' }) )`
const SidebarNav = styled.nav`
    display: flex;
    justify-content: center;
    background: #141414;
    width: 280px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${({ $sidebar }) => ($sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
    overflow-x: auto;
    console.log(props)
`;

const SidebarWrap = styled.div`
    width: 100%;
`;

const NavbarElements = styled.div`
    display: none;
    @media only screen and (min-width: 1200px) {
        display: flex;
    }
`;

const LoginBar = styled.div`
    display: flex;
`;

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
    useEffect(() => {
      let lastScrollY = window.scrollY;
  
      const updateScrollDirection = () => {
        const scrollY = window.scrollY;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
  
    return scrollDirection;
};

const Navbar = (props) => {
    const scrollDirection = useScrollDirection();
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar((sidebar) => !sidebar);
    let menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if(!menuRef.current.contains(e.target)){
                setSidebar(false);
            }   
        };
        document.addEventListener("mousedown", handler);
        return() => {
            document.removeEventListener("mousedown", handler);
        }
    });

    // Sticky Menu Area
    /* useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
        window.removeEventListener("scroll", isSticky);
        };
    }); */

    /* Method that will fix header after a specific scrollable */
    /* const isSticky = (e) => {
        const header = document.querySelector(Nav);
        const scrollTop = window.scrollY;
        scrollTop >= 250
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }; */
    
    return (
        <>
            <Nav className={`header ${ scrollDirection === "down" ? "hide" : "show"}`}>
                <NavLogo>
                    <NavIcon to="#">
                        <Feather.FiMenu onClick={ showSidebar } />
                    </NavIcon>
                    <Logo to="/">
                        <Icon icon="modern-logo-horizontal" width="160" height="80" />
                    </Logo>    
                </NavLogo>

                <NavbarElements>
                    {NavBarData.map((item, index) => {
                        return <NavMenu item={ item } key={ index } />;
                    })}
                </NavbarElements>

                <LoginBar>
                    <LoginBarContainer token={props.token} logOut={props.logOut} />
                </LoginBar>   
            </Nav>
            <SidebarNav $sidebar={sidebar}>
                <SidebarWrap ref={menuRef}>
                    <NavLogo>
                        <NavIcon to="#">
                            <Feather.FiX onClick={ showSidebar } />
                        </NavIcon>
                        <Logo to="/">
                            <Icon icon="modern-logo-horizontal" width="160" height="80" />
                        </Logo>    
                    </NavLogo>
                    {NavBarData.map((item, index) => {
                        return <SubMenu item={ item } key={ index } setSidebar={ setSidebar } />;
                    })}
                </SidebarWrap>
            </SidebarNav>
        </>  
    )
}

export default Navbar;
