import React from 'react';
import Service from "./Service";
//import axios, * as others from "axios";
import { withParams } from '../../../hoc/withParams';
import {connect} from "react-redux";
import {setServicePage, toggleIsFetching} from "../../../redux/service-reducer";
import Preloader from "../../common/Preloader/Preloader";
import { pagesAPI } from "./../../../api/api";


class ServiceContainer extends React.Component {
   
    componentDidMount() {
        this.props.toggleIsFetching(true);
        const { pageId } = this.props.params;
        pagesAPI.getService(pageId).then(response => {
            this.props.setServicePage(response);
            this.props.toggleIsFetching(false);
        });      
    }
    componentDidUpdate(prevProps) {
        if (this.props.params.pageId !== prevProps.params.pageId) {
            this.props.toggleIsFetching(true);
            let pageId = this.props.params.pageId;
            pagesAPI.getService(pageId).then(response => {
                this.props.setServicePage(response);
                this.props.toggleIsFetching(false);
            }); 
        }
    }

   render() {
        return <>{ this.props.isFetching ? <Preloader /> : <Service {...this.props} /> }</>
   }
}

let mapStateToProps = (state) => ({
    page: state.servicePage,
    params: state.servicePage.params,
    isFetching: state.servicePage.isFetching,
    //services: state.servicesPage.services
});

let WidthUrlDataContainerComponent = withParams(ServiceContainer); 

export default connect(mapStateToProps, {setServicePage, toggleIsFetching})(WidthUrlDataContainerComponent);
