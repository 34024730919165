import { PostType } from '../types/types';
import { blogAPI } from "../api/api";

const SET_POSTS_ITEMS = 'SET_POSTS_ITEMS';
const TOGGLE_IS_FETCHIG = 'TOGGLE_IS_FETCHIG';
const SET_POSTS_CURRENT_PAGE = 'SET_POSTS_CURRENT_PAGE';
const SET_TOTAL_POSTS_COUNT = 'SET_TOTAL_POSTS_COUNT';

type PostsType = {
    id: number
    blog_id: number
    contact_id: number
    contact_name: string
    datetime: string
    title: string
    status: string
    text: string
    // ещё какое-то количество переменныхы
    short_content: string
}
let initialState = {
    posts: [] as Array<PostsType>,
    pageSize: 6,
    totalCount: 0,
    currentPage: 1,
    isFetching: false
};
type InitialStateType = typeof initialState;
const blogReducer = (state = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case SET_POSTS_ITEMS: {
            return { ...state, ...action.posts };
        }
        case SET_POSTS_CURRENT_PAGE: {
            return { ...state, currentPage: action.currentPage };
        }
        case SET_TOTAL_POSTS_COUNT: {
            return { ...state, totalCount: action.totalCount };
        }
        case TOGGLE_IS_FETCHIG: {
            return { ...state, isFetching: action.isFetching };
        }
        default:
            return state;
    }
};
export const setPosts = (posts: Array<PostsType>) => ({ type: SET_POSTS_ITEMS, posts });
export const setCurrentPage = (currentPage: number) => ({ type: SET_POSTS_CURRENT_PAGE, currentPage });
export const setPostsTotalCount = (totalCount: number) => ({ type: SET_TOTAL_POSTS_COUNT, totalCount });
export const toggleIsFetching = (isFetching: boolean) => ({ type: TOGGLE_IS_FETCHIG, isFetching });

export const requestPosts = (page: number, pageSize: number) => async (dispatch: any) => {
    dispatch( toggleIsFetching(true) );
    dispatch( setCurrentPage(page) );

    let limit = pageSize;
    let offset = limit * page - limit;
    const response = await blogAPI.getPosts(offset, limit);

    dispatch( toggleIsFetching(false) );
    dispatch( setPosts(response) );
}
export const getPostsCount = () => async (dispatch: any) => {
    const response = await blogAPI.getBlogInfo();
    dispatch( setPostsTotalCount(response.qty) );
}

export default blogReducer;
