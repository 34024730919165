import {applyMiddleware, combineReducers, legacy_createStore as createStore} from "redux";
import messagesReducer from './messages-reducer';
import servicesReducer from './services-reducer';
import usersReducer from "./users-reducer";
import profileReducer from "./profile-reducer";
import authReducer from "./auth-reducer";
import serviceReducer from './service-reducer';
import blogReducer from './blog-reducer';
import blogPostReducer from './blog-post-reducer';
import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import appReducer from "./app-reducer";

let rootReducer = combineReducers({
    messagesPage: messagesReducer,
    servicesPage: servicesReducer,
    usersPage: usersReducer,
    profilePage: profileReducer,
    auth: authReducer,
    servicePage: serviceReducer,
    blog: blogReducer,
    blogPost: blogPostReducer,
    form: formReducer,
    app: appReducer
})

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
export default store;