import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(NavLink)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-size: 0.875em;
    text-transform: uppercase;
    height: 60px;
    padding: 0 20px;

    &:hover {
        background: #292929;
        border-left: 4px solid #ff4848;
    }
    & svg + span {
        margin-left: 1rem;
    } 
`;
const SidebarLabel= styled.span``;

const DropdownLink = styled(Link)`
    background: #333333;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #d3d3d3;
    text-decoration: none;
    font-size: 0.875em;
    text-transform: uppercase;

    &:hover {
        background: #ff4848;
    } 
`;

const SubMenu = ({ item, setSidebar }) => {

    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => { 
        setSubnav(!subnav);
        //setSidebar(false);
    }
    return (
        <>
            <SidebarLink to={item.path} onClick={ item.subNav ? showSubnav : setSidebar }>
                <div>
                    {item.icon}
                    <SidebarLabel>
                        {item.title}
                    </SidebarLabel>
                </div>
                <div>
                    { item.subNav && subnav 
                    ? item.iconOpened 
                    : item.subNav 
                    ? item.iconClosed 
                    : null }
                </div>
                
            </SidebarLink>
            {subnav && item.subNav.map((item, index) => {
                return (
                    <DropdownLink to={item.path} key={index} onClick={  setSidebar }>
                        {item.icon}
                        <SidebarLabel>
                            {item.title}
                        </SidebarLabel>
                    </DropdownLink>
                )
            })}
        </>
    )
}

export default SubMenu
