import React, { Suspense, lazy } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { AuthContext } from 'react-oauth2-code-pkce';
import Preloader from './components/common/Preloader/Preloader';
import store from './redux/redux-store';
import { Provider, connect } from 'react-redux';
import { initializeApp } from "./redux/app-reducer";
import { compose } from 'redux';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from './components/Navbar/Navbar';


import Homepage from "./components/Homepage/Homepage";
import ServicesContainer from "./components/Services/ServicesContainer";
import ServiceContainer from "./components/Services/Service/ServiceContainer";

import ProfileContainer from "./components/Profile/ProfileContainer";
import MessagesContainer from "./components/Messages/MessagesContainer";
import Login from "./components/Login/Login";
import ErrorPage from './components/ErrorPage/ErrorPage'
//import Blog from './components/Blog/Blog';
const Blog = lazy(() => import('./components/Blog/Blog'));
//import Post from './components/Blog/Post';
const Post = lazy(() => import('./components/Blog/Post'));
//import UsersContainer from "./components/Users/UsersContainer";
const UsersContainer = lazy(() => import('./components/Users/UsersContainer'));


class App extends React.Component {
    catchAllnhandledreErrors = (event) => {
        //alert(event.reason);
    }
	componentDidMount() {
		this.props.initializeApp();	
        //Это полезно для отладки и обеспечения резервной обработки ошибок в непредвиденных ситуациях
        window.addEventListener("unhandledrejection", this.catchAllnhandledreErrors);
	}
    // когда компонента будет демонтирована
    componentWillUnmount() {
        window.removeEventListener("unhandledrejection", this.catchAllnhandledreErrors);
    }	

	render() {
		const { token, login, logOut, error, loginInProgress } = this.context;
		if (!this.props.initialized) {
			return <Preloader />;
		}
        
		return (			           				
            <div className="App">
                <Helmet>
                    <title>Modern app</title>
                    <meta
                        name="description"
                        content='Modern app'
                    />
                </Helmet>
                <Navbar token={token} logOut={logOut} />
                {/*<HeaderContainer token={token} logOut={logOut} />*/}
                
                {/*loginInProgress ? <Preloader /> : ''*/}

                <Routes>
                    <Route path="*" element={<ErrorPage />} />
                    <Route path="/" element={ <Homepage /> } />
                    <Route path="/services/" element={<ServicesContainer />} />
                    <Route path="/services/:pageId" element={<ServiceContainer />} />
                    <Route path="/messages/" element={<MessagesContainer token={token} />} />
                    <Route path="/team/" element={ 
                        <Suspense fallback={<Preloader />}>
                            <UsersContainer token={token} pageTitle="Team"/>
                        </Suspense>
                    } />
                    <Route path="/profile/:userId?" element={<ProfileContainer token={token} />} />
                    <Route path="/login/" element={ <Login token={token} login={login} logOut={logOut} error={error}/> } />	
                    <Route path="/blog/" element={
                        <Suspense fallback={<Preloader />}><Blog/></Suspense>
                    } /> 
                    <Route path="/blog/:postId" element={
                        <Suspense fallback={<Preloader />}><Post /></Suspense>
                    } /> 
                </Routes>
            </div>
		);
	}
};
App.contextType = AuthContext;

let mapStateToProps = (state) => {
    return {
        initialized: state.app.initialized
    }
}

let AppContainer = compose(
	connect(mapStateToProps, { initializeApp })
)(App);

const ModernApp = () => {
    return <Router basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
            <HelmetProvider>
                <AppContainer/>
            </HelmetProvider>
        </Provider>
    </Router>
}
//export default App;
export default ModernApp
