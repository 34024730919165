//import React from 'react';
import { withAuthRedirect } from '../../hoc/withAuthRedirect';
import {
   addMessageActionCreator
   //,updateNewMessageTextActionCreator
} from '../../redux/messages-reducer';
import Messages from './Messages';
//import StoreContext from '../../StoreContext';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";

{/*
const MessagesContainer = (props) => {
   //let state = props.store.getState();
   //let addMessage = () => {
   //   props.store.dispatch(addMessageActionCreator());
   //};

   //let onMessageChange = (text) => {
   //   let action = updateNewMessageTextActionCreator(text);
   //   props.store.dispatch(action);
   //};

   return <StoreContext.Consumer> 
      {
         (store) => {
            let state = store.getState();
            let addMessage = () => {
               store.dispatch(addMessageActionCreator());
            };

            let onMessageChange = (text) => {
               let action = updateNewMessageTextActionCreator(text);
               store.dispatch(action);
            };
            return <Messages newMessageBody={state.newMessageBody} 
                     updateNewMessage={onMessageChange} 
                     addMessage={addMessage} 
                     posts={state.messagesPage.posts} />
         }
      }
      </StoreContext.Consumer>

};
*/}

const mapStateToProps = (state) => {
   return {
      posts: state.messagesPage.posts
      //newMessageBody: state.messagesPage.newMessageBody
   }
}

let mapDispatchToProps = (dispatch) => {
   return {
      //updateNewMessage: (text) => {
      //   dispatch(updateNewMessageTextActionCreator(text));
      //},
      addMessage: (newMessageBody) => { 
         dispatch(addMessageActionCreator(newMessageBody));
      }
   }
}
// redirect
let AuthRedirectComponent = withAuthRedirect(Messages);
const MessagesContainer = connect(mapStateToProps, mapDispatchToProps)(AuthRedirectComponent);

//const MessagesContainer = connect(mapStateToProps, mapDispatchToProps)(Messages);

export default MessagesContainer;
