import React from "react";
import * as Feather from "react-icons/fi";

export const NavBarData = [
    {
        title: "Studio",
        path: "/",
        icon: <Feather.FiHome />
    },
    {
        title: "Services",
        path: "/services/",
        icon: <Feather.FiLayers />,
        iconClosed: <Feather.FiChevronRight />,
        iconOpened: <Feather.FiChevronDown />,
        subNav: [
            {
                title: "Development",
                path: "/services/11/",
                icon: <Feather.FiHome />
            },
            {
                title: "Web-design",
                path: "/services/3/",
                icon: <Feather.FiHome />
            },
            {
                title: "Site layout",
                path: "/services/2/",
                icon: <Feather.FiHome />
            }
        ]
    },
    {
        title: "Messages",
        path: "/messages/",
        icon: <Feather.FiMessageCircle />,
    },
    {
        title: "Team",
        path: "/team/",
        icon: <Feather.FiUsers />
    },
    {
        title: "Profile",
        path: "/profile/",
        icon: <Feather.FiUser />
    },
    {
        title: "Blog",
        path: "/blog/",
        icon: <Feather.FiCoffee />
    },
    {
        title: 'Support',
        path: '/support/',
        icon: <Feather.FiTool />
    }
    
]