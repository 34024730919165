import React, { memo } from 'react';
//import { Parser } from 'html-to-react'
//import MetaTags from 'react-meta-tags';
//import {Helmet} from "react-helmet";
import { Helmet} from "react-helmet-async";
//import { useParams } from 'react-router';

const Service = memo( function Service(props) {
	//debugger;
	//console.log(props.page);
	//console.log("RENDER");
	//let params = useParams();
	//console.log(params.pageId);
	return (
		<section className="container">
			<Helmet>
				<title>{props.page.title || props.page.name }</title>
				<meta
					name="description"
					content={props.params['description']}
				/>
			</Helmet>
			<h1>{props.page.name}</h1>

			{/*Parser().parse(props.page.content)*/}
			<div dangerouslySetInnerHTML={{ __html: props.page.content }}></div>
			
		</section>
	);
});

export default Service;
