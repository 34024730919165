import React from 'react';
import s from '../Messages.module.css';

const Message = (props) => {
  return (
    <div className={s.message}>
      <div>
         <div className={s.user_image}>
            <img
               src={`${process.env.PUBLIC_URL}/images/139420490.100x100.jpg`}
               alt=""
            />
         </div>
      </div>
      <div className={s.item}>
        {props.message}
        {/*
        <div>
            <span>like</span> {props.likesCount}
         </div>
          */} 
      </div> 
    </div>
  );
};

export default Message;
