import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Input, Checkbox, Textarea } from './../../common/FormControls/FormControls';
import style from './ProfileInfo.module.css';

const ProfileForm = ({handleSubmit, contacts, error}) => {

    return (
        <form onSubmit={ handleSubmit } className={style.profile_form}>           
            <div className='input-field'>
                <button>Save</button>
            </div>
            {error && <p className={style.formControlTextError}>{error}</p>}

            <Field name="fullName" type="text" placeholder="Full Name"
            component={Input} validate={[ ]} label="Full Name" />
 
            <Field name="aboutMe" type="text" placeholder="About me"
            component={Input} validate={[ ]} label="About me" />

            <Field name="lookingForAJob" type="checkbox"
            component={Checkbox} validate={[ ]} label="Looking for a job" />
                
            <Field name="lookingForAJobDescription" placeholder="My professional skills" 
            component={Textarea} validate={[ ]} label="My professional skills" />
            
            <div className='input-field'>
                Contacts: 
                <div className='flex row wrap'>
                    {Object.keys(contacts || {}).map(key => {
                        return (
                            <div className='col s12 m6 l4' key={key}>
                                <Field name={`contacts.${key}`} type="text" placeholder={key} 
                                    component={Input} validate={[ ]} label={key} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </form>
    )
}
const ProfileDataForm = reduxForm({ form: 'ProfileForm', destroyOnUnmount: false })(ProfileForm);
export default ProfileDataForm;