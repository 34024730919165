import { updateObjectInArray } from "../utils/validators/object-helpers";
import { usersAPI } from "../api/api";
import { UserType } from '../types/types';
import { type } from "os";

const FOLLOW = 'FOLLOW';
const UNFOLLOW = 'UNFOLLOW';
const SET_USERS = 'SET_USERS';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_TOTAL_USERS_COUNT = 'SET_TOTAL_USERS_COUNT';
const TOGGLE_IS_FETCHIG = 'TOGGLE_IS_FETCHIG';
const TOGGLE_IS_FOLLOWING_PROGRESS = 'TOGGLE_IS_FOLLOWING_PROGRESS';

let initialState = {
    users: [] as Array<UserType>,
    pageSize: 12,
    totalCount: 0,
    currentPage: 1,
    isFetching: false,
    followingInProgress: [] as Array<number> // array of users id

};
type initialStateType = typeof initialState;

const usersReducer = (state = initialState, action: any): initialStateType => {
  switch (action.type) {
    case FOLLOW:
      return {
        ...state,
        users: updateObjectInArray(state.users, action.userId, "id", {followed: true})
        /*users: state.users.map((u) => {
          if (u.id === action.userId) {
            return { ...u, followed: true };
          }
          return u;
        })*/
      };
    case UNFOLLOW:
      return {
        ...state,
        users: updateObjectInArray(state.users, action.userId, "id", {followed: false})
      };
    case SET_USERS: {
      return { ...state, users: action.users };
    }
    case SET_CURRENT_PAGE: {
        return { ...state, currentPage: action.currentPage };
    }
    case SET_TOTAL_USERS_COUNT: {
        return { ...state, totalCount: action.count };
    }
    case TOGGLE_IS_FETCHIG: {
        return { ...state, isFetching: action.isFetching };
    }
    case TOGGLE_IS_FOLLOWING_PROGRESS: {
        return { 
          ...state, 
          followingInProgress: action.isFetching 
            ? [...state.followingInProgress, action.userId] 
            : state.followingInProgress.filter(id => id != action.userId) 
        };
    }
    default:
      return state;
  }
};
type FollowSuccessActionType = {
    type: typeof FOLLOW
    userId: number
}
export const followSuccess = (userId: number): FollowSuccessActionType => ({ type: FOLLOW, userId });
type UnfollowSuccessActionType = {
    type: typeof UNFOLLOW
    userId: number
}
export const unfollowSuccess = (userId: number): UnfollowSuccessActionType => ({ type: UNFOLLOW, userId });
type SetUsersActionType = {
    type: typeof SET_USERS
    users: Array<UserType>
}
export const setUsers = (users: Array<UserType>): SetUsersActionType => ({ type: SET_USERS, users });
type SetCurrentPageActionType = {
    type: typeof SET_CURRENT_PAGE
    currentPage: number
}
export const setCurrentPage = (currentPage: number): SetCurrentPageActionType => ({ type: SET_CURRENT_PAGE, currentPage });
type SetUsersTotalCountActionType = {
    type: typeof SET_TOTAL_USERS_COUNT
    count: number
}
export const setUsersTotalCount = (totalCount: number): SetUsersTotalCountActionType => ({ type: SET_TOTAL_USERS_COUNT, count: totalCount });
type ToggleIsFetchingActionType = {
    type: typeof TOGGLE_IS_FETCHIG
    isFetching: boolean
}
export const toggleIsFetching = (isFetching: boolean): ToggleIsFetchingActionType => ({ type: TOGGLE_IS_FETCHIG, isFetching });
type TogglefollowingProgressActionType = {
    type: typeof TOGGLE_IS_FOLLOWING_PROGRESS
    isFetching: boolean
    userId: number
}
export const togglefollowingProgress = (isFetching: boolean, userId: number): TogglefollowingProgressActionType => ({ 
    type: TOGGLE_IS_FOLLOWING_PROGRESS, 
    isFetching, 
    userId 
});

// замыкание
export const requestUsers = (page: number, pageSize: number) => {
    return async (dispatch: any) => {
        dispatch( toggleIsFetching(true) );
        dispatch( setCurrentPage(page) );
        let data = await usersAPI.getUsers( page, pageSize );
        //usersAPI.getUsers( page, pageSize ).then(data => {
            dispatch( toggleIsFetching(false) );
            dispatch( setUsers(data.items || data) );
            dispatch( setUsersTotalCount(data.totalCount) );   
        //});
  }
}

const followUnfollowFlow = async (dispatch: any, userId: number, apiMethod: any, actionCreator: any) => {
    dispatch(togglefollowingProgress(true, userId));
    let response = await apiMethod(userId);
    if (response.data.resultCode === 0) {
        dispatch(actionCreator(userId));
    }
    dispatch(togglefollowingProgress(false, userId)); 
}
export const follow = (userId: number) => {
    return async (dispatch: any) => {
        followUnfollowFlow(dispatch, userId, usersAPI.follow.bind(userId), followSuccess);  
    }
}
export const unfollow = (userId: number) => {
    return async (dispatch: any) => {
        followUnfollowFlow(dispatch, userId, usersAPI.unfollow.bind(userId), unfollowSuccess); 
    }
}

export default usersReducer;