const SET_SERVICE_PAGE = 'SET_SERVICE_PAGE';
const TOGGLE_IS_FETCHIG = 'TOGGLE_IS_FETCHIG';

let initialState = {
    page: [],
    params: [],
    isFetching: false,
    //slug: null
};

const serviceReducer = (state = initialState, action) => {

    switch(action.type) {
        case SET_SERVICE_PAGE: {
            return {
                ...state, ...action.data
            }
        }
        case TOGGLE_IS_FETCHIG: {
            return { ...state, isFetching: action.isFetching };
        }
        default:
            return state;
    }
}

export const setServicePage = (data) => ({type: SET_SERVICE_PAGE, data})
export const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHIG, isFetching });

export default serviceReducer;