//import axios, * as others from "axios";
import axios from 'axios';

//axios.get(`https://www.vmodern.ru/api.php/team.users.getList?access_token=f507aae1952a285c4d712975c36cc8a7`)
//axios.get(`https://www.vmodern.ru/team/?api=1&page=${this.props.currentPage}&count=${this.props.pageSize}`)
//axios.get(`https://www.vmodern.ru/team/?api=1&page=${pageNamber}&count=${this.props.pageSize}`)

//filter%5Baccess%5D%5B%5D=site
//filter[access][]=site
//filter[group_id]=4

const instance_modern = axios.create({
    //'https://www.vmodern.ru/api.json/'
    baseURL: 'https://www.vmodern.ru/api.php/',
    headers: {
        'Authorization': 'Bearer f507aae1952a285c4d712975c36cc8a7'
    }
});

const instance_samurai = axios.create({
    withCredentials: true,
    baseURL: 'https://social-network.samuraijs.com/api/1.0/',
    headers: {
        'API-KEY': 'd84d0feb-2400-438c-8493-ea0fdd961592'
    } 
});

export const usersAPI = {
    getUsersModern(currentPage = 1, pageSize = 10) {
        return instance_modern.get(`team.users.getList?page=${currentPage}&count=${pageSize}&api=1&getUsers=1&filter[group_id]=0`)
            .then(response => { 
                return response.data;
            });
    },
    getUsers(currentPage = 1, pageSize = 10) {
        return instance_samurai.get(`users?page=${currentPage}&count=${pageSize}`)
            .then(response => { 
                return response.data;
            });
    },
    follow(userId) {
        return instance_samurai.post(`follow/${userId}`); 
    },
    unfollow(userId) {
        return instance_samurai.delete(`follow/${userId}`);    
    },
    getProfile(userId = 2) {
        console.warn('obsolete method, please profileAPI.')
        return profileAPI.getProfile(userId);
    }
}

export const profileAPI = {
    getProfile(userId) {
        return instance_samurai.get(`profile/${userId}`);
    },
    getStatus(userId) {
        return instance_samurai.get(`profile/status/`+ userId)
    },
    updateStatus(status) {
        return instance_samurai.put(`profile/status`, {'status': status})
    },
    savePhoto(file) {
        const formData = new FormData();
        formData.append('image', file)
        return instance_samurai.put(`profile/photo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    saveProfile(profile) {
        return instance_samurai.put(`profile`, profile);
    }

}

export const authAPI = {
    //axios.get(`https://social-network.samuraijs.com/api/1.0/auth/me`, { withCredentials: true })
    me(token) {
        return axios.get(`https://www.webasyst.com/id/api/v1/profile/`, { headers: {'Authorization': 'Bearer ' + token} });
    },
    meSamurai() {
        return instance_samurai.get(`auth/me`);
    },
    login(email, password, rememberMe = false, captcha = null) {
        return instance_samurai.post(`auth/login`, { email, password, rememberMe, captcha });
    },
    logout() {
        return instance_samurai.delete(`auth/login`);
    }
}
export const securityAPI = {
    getCaptchaUrl() {
        return instance_samurai.get(`security/get-captcha-url`);
    }
}

export const pagesAPI = {
    getServices() {
        return instance_modern.get(`site.page.getList?domain_id=1&params=1`)
        .then(response => { 
            if (response.status == '200') {
                return response.data.pages[0].childs.filter(item => item.status === '1');
            }
        });
    },
    getService(pageId) {
        return instance_modern.get(`site.page.getInfo?id=${pageId}`)
        .then(response => { 
            return response.data;
        });
    }
}
export const blogAPI = {
    getBlogInfo( blogId = 1 ) {
        return instance_modern.get(`blog.blog.getInfo?id=${blogId}`)
        .then(response => { 
            return response.data;
        });
    },
    getPosts( offset, limit, blogId = 1 ) {
        return instance_modern.get(`blog.post.search?hash=blog/${blogId}&offset=${offset}&limit=${limit}`)
        .then(response => { 
            if (response.status == '200') {
                return response.data;
            }
        });
    },
    getPost( postId ) {
        return instance_modern.get(`blog.post.getInfo?id=${postId}`)
        .then(response => { 
            if (response.status == '200') {
                return response.data;
            }
        });
    }
}
