import React from "react";
import loading from "./../../../assets/images/loading.gif"
import style from "./Preload.module.css";

let Preloader = () => {
    return (
        <div className={style.preload}>
            <img src={loading} />
        </div>
    )
}

export default Preloader;