//import { useAsyncError } from "react-router-dom";

export default function ErrorPage() {
  //const error = useAsyncError();
  //console.log(error);

  return (
    <section className="container" id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{/*error.statusText || error.message*/}</i>
      </p>
    </section>
  );
}
