import React from "react";

let Price = (props) => {
    let price = props.price*1 || 0;
    //price.toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})
    return (
        <>
        {price > 0 && 
            <p>
                от{" "}
                <span className="price">
                    {price.toLocaleString('ru-RU')} <span className="ruble">₽</span>
                </span>
            </p>
        }
        </>
    )
}

export default Price;