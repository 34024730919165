import React from 'react';
//import {
//   addMessageActionCreator,
//   updateNewMessageTextActionCreator
//} from '../../redux/messages-reducer';
import Message from './Message/Message';
import { Field, reduxForm } from 'redux-form';
import { requierd, maxLengthCreator } from './../../utils/validators/validators';
import { Textarea } from '../common/FormControls/FormControls';


const maxLength10 = maxLengthCreator(10);

const AddMessageForm = (props) => {
	return (
	  <form onSubmit={props.handleSubmit}>
		
		<Field
			name="newMessageBody"
			component={Textarea}
			placeholder="Enter your message"
			validate={ [ requierd, maxLength10 ] }
		/>
		
		<div className="input-field">
		  <button>Add post</button>
		</div>
	  </form>
	);
  };
  const AddMessageFormRedux = reduxForm({ form: 'dialogAddMessageForm' })(
		AddMessageForm
  );

const Messages = (props) => {

	let messagesElements = props.posts.map((p) => (
		<Message key={p.id} message={p.message} likesCount={p.likesCount} />
	));
	//let addMessage = () => {
	//   props.dispatch(addMessageActionCreator());
	//};

	//let onMessageChange = () => {
	//   let text = newMessageElement.current.value;
	//   let action = updateNewMessageTextActionCreator(text);
	//   props.dispatch(action);
	//};

	//let addMessage = () => {
	//	props.addMessage();
	//};
	//let newMessageElement = React.createRef();
	//let onMessageChange = () => {
	//	let text = newMessageElement.current.value;
	//	props.updateNewMessage(text);
	//};

	//console.log(props.addMessage)

	let addNewMessage = (values) => {
		//alert( values.newMessageBody );
		props.addMessage(values.newMessageBody);
	};

	return (
		<section className="container">
			<h1>Сообщения</h1>
			<div>{messagesElements}</div>

			<AddMessageFormRedux onSubmit={addNewMessage} />
			{/*
			<div className='input-field'>  
				<textarea onChange={ onMessageChange } ref={ newMessageElement } value={props.newPostText} />
			</div>
			<div className='input-field'>
				<button onClick={addMessage}>Add post</button>
			</div>
			*/}
		</section>
	);
};

export default Messages;
