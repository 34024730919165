import React from "react";
import style from "./FormControl.module.css";

export const Textarea = ({ input, meta, ...props  }) => {
    const hasError = meta.error && meta.touched;
    return (
        <div className={"input-field "+ style.formControl +" "+ (hasError ? style.error : '')}>
            {props.label && <div className="field-name">{props.label}:</div>}
            <textarea {...input} {...props} />
            {hasError && <span className={style.formControlText}>{meta.error}</span>}
        </div>
    )
} 

export const Input = ({ input, meta, ...props  }) => {
    const hasError = meta.error && meta.touched;
    return (
        <div className={"input-field "+ style.formControl +" "+ (hasError ? style.error : '')}>
            {props.label && <div className="field-name">{props.label}:</div>}
            <input {...input} {...props} />
            {hasError && <span className={style.formControlText}>{meta.error}</span>}
        </div>
    )
}

export const Checkbox = ({ input, meta, ...props  }) => {
    const hasError = meta.error && meta.touched;
    return (
        <div className={"input-field "+ style.formControl +" "+ (hasError ? style.error : '')}>
            <label htmlFor={input.name}>
                <input className="filled-in" id={input.name} {...input} {...props} /> <span>{props.label}</span>
            </label>
            {hasError && <span className={style.formControlText}>{meta.error}</span>}
        </div>
    )
}