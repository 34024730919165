import React, { useEffect, useState, memo } from 'react';
import Icon from "../../common/IconsSvg/IconsSvg";
import style from "./ProfileInfo.module.css"

const ProfileStatusWithHooks = memo(props => {
    
    let [editMode, setEditMode] = useState(false);
    let [status, setStatus] = useState(props.status);

    useEffect( () => {
        setStatus(props.status);
    }, [props.status]);

    const ActivateEditMode = () => {
        setEditMode(true);
    }
    const deActivateEditMode = () => {
        setEditMode(false);
        if (status !== props.status) {
            props.updateStatus(status);
        }
    }
    const onStatusChange = (e) => {
        setStatus(e.currentTarget.value);
    }
   
    //const [count, setCount] = useState(0);

    return (
        <div>
            {/*<p>You clicked {count} times</p>
            <button onClick={() => setCount(count + 1)}>
                Click me
            </button>
            */}
            Status:
            {!editMode &&           
                <strong onClick={ ActivateEditMode } className={style.profile_status}>
                    {props.status || 'No status'} <Icon icon="edit" width="16" height="16"/>
                </strong>           
            }
            {editMode &&            
                <input value={ status } 
                type='text' onChange={ onStatusChange } 
                autoFocus={ true } onBlur={ deActivateEditMode } />           
            }
        </div>
    )   
});

export default ProfileStatusWithHooks;