import React from "react";

let iconSvg = (props) => {
    return (     
        <svg width={props.width ? props.width : '24'} height={props.height ? props.height : '24'}>
            <use xlinkHref={`/images/svg/sprite.svg#${props.icon}`}></use>
        </svg>
    )
}

export default iconSvg;