//import store from './redux/redux-store';
import React, { StrictMode } from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
//import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
//import { Provider } from 'react-redux';
//import StoreContext, { Provider } from './StoreContext';
import { AuthProvider } from 'react-oauth2-code-pkce';

//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(
//  <React.StrictMode>
//     <App data={state.services} addPost={addPost} />
//  </React.StrictMode>
//);

const authConfig = { 
  clientId: '6bddfabbe5d42cb0ad88da2b69270e42',
  authorizationEndpoint: 'https://www.webasyst.com/id/oauth2/auth/code',
  tokenEndpoint: 'https://www.webasyst.com/id/oauth2/auth/token',
  redirectUri: window.location.origin +'/login',
  scope: "profile+license:read",
  state: '8768679679696544545456645327',
  preLogin: () => localStorage.setItem('preLoginPath', window.location.pathname),
  postLogin: () => window.location.replace(localStorage.getItem('preLoginPath') || ''),
  decodeToken: false,
  autoLogin: false,
  storage: 'session' // default: 'local'
}

const root = createRoot(document.getElementById('root'));
//let rerenderEntireTree = (state) => {
  root.render(
    <StrictMode>        
        <AuthProvider authConfig={authConfig}>
          <App />
        </AuthProvider>     
    </StrictMode>
    //<StoreContext.Provider value={store}>
    //    <App />
    //</StoreContext.Provider>

  );
//};

//rerenderEntireTree(store.getState());

//store.subscribe(rerenderEntireTree);
//store.subscribe(() => {
//    let state = store.getState();
//    rerenderEntireTree(state);
//});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
