import React, { useContext } from 'react';
//import { AuthContext, AuthProvider, TAuthConfig, IAuthContext } from 'react-oauth2-code-pkce';
import Icon from '../common/IconsSvg/IconsSvg';
import { Field, reduxForm } from 'redux-form';
import { Input, Checkbox } from '../common/FormControls/FormControls';
import style from '../common/FormControls/FormControl.module.css'
import { requierd, maxLengthCreator } from './../../utils/validators/validators';
import { loginToSamurai, logoutToSamurai } from "./../../redux/auth-reducer";
import { connect } from 'react-redux';

const LoginForm = ({captchaUrl,error, handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit}>
            
            <Field name="email" type="text" placeholder={'Email'}
                component={Input} 
                validate={ [ requierd ] } 
            />
            <Field name="password" type="password" placeholder={'Password'}
                component={Input} 
                validate={ [ requierd ] } 
            />            
            <Field name="rememberMe" type="checkbox"
                component={Checkbox} 
                validate={ [ requierd ] }
                label="remember me"
                value="true"
            />
            { captchaUrl && (<>
                <img src={captchaUrl} /> 
                <Field name="captcha" type="text" placeholder={'Symbols from image'} 
                component={Input} validate={ [ requierd ] } />
            </>)}


            {error && <p className={style.formControlTextError}>{error}</p>}
            <div className='input-field'>
                <button type="submit">Login</button>
            </div>
        </form>
    );
}
const LoginReduxForm = reduxForm({ form: 'Login' })(LoginForm);


const LoginInfo = (props) => {
    //function LoginInfo(): JSX.Element {
    //const { tokenData, token, login, logOut, idToken, error }: IAuthContext = useContext(AuthContext);
    //const { token, login, logOut, error } = useContext(AuthContext);

    const onSubmit2 = (formData) => {
        //console.log(formData);
        props.loginToSamurai(formData.email, formData.password, formData.rememberMe, formData.captcha)
    }

    if (props.error) {
        return (
            <section className="container">
                <div style={{ color: 'red' }}>
                    An error occurred during authentication: {props.error}
                </div>
                <button onClick={() => props.logOut()}>Logout</button>
            </section>
        );
    }

    return (
        <section className="container">

            <h1>Login</h1>
            
            {props.isAuth 
            ? <button onClick={ props.logoutToSamurai }>Logout</button> 
            : <LoginReduxForm  onSubmit={onSubmit2} captchaUrl={props.captchaUrl} />
            }
            <p>Или войдите с помощью</p>
            {props.token ? (
                <button onClick={() => props.logOut()}>
                    <Icon icon="webasyst" width="16" height="16" /> Logout Webasyst ID
                </button>
            ) : (
                <button onClick={() => props.login()}>
                    <Icon icon="webasyst" width="16" height="16" /> Login Webasyst ID
                </button> 
            )}

        </section>
    );
};
const mapStateToProps = (state) => ({
	isAuth: state.auth.isAuth,
    captchaUrl: state.auth.captchaUrl
});

export default connect(mapStateToProps, { loginToSamurai, logoutToSamurai })(LoginInfo);
