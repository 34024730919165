import React from 'react';
import Profile from "./Profile";
//import axios, * as others from "axios";
import {connect} from "react-redux";
import {getUserProfile, getStatus, updateStatus, savePhoto, saveProfile} from "../../redux/profile-reducer";
//import {withRouter} from 'react-router-dom';
//import { useLocation, useNavigate, useParams } from 'react-router';
//import { usersAPI } from "./../../api/api";
//import { Navigate } from "react-router-dom";
import { withAuthRedirect } from '../../hoc/withAuthRedirect';
import { compose } from 'redux';
import { withParams } from '../../hoc/withParams';

// function withRouter(Component) {
//     function ComponentWithRouterProp(props) {
//         let location = useLocation();
//         let navigate = useNavigate();
//         let params = useParams();
//         return (
//             <Component
//                 {...props}
//                 router={{ location, navigate, params }}
//             />
//         );
//     }
//     return ComponentWithRouterProp;
// }

class ProfileContainer extends React.Component {

    refreshProfile() {
        let userId = this.props.params.userId
        if (!userId) {
            userId = this.props.authorizedUserId // 29742;
            //if (!userId) {
            //    this.props.history.push('/login/');
            //}
        }         
        this.props.getUserProfile(userId);
        this.props.getStatus(userId);       
    }
    
    componentDidMount() {
        this.refreshProfile();
        //console.log(this.props);
        //usersAPI.getProfile(userId).then(response => {
        //    this.props.setUserProfile(response.data);
        //});
    }

    componentDidUpdate(prevProps) {
        if (this.props.params.userId !== prevProps.params.userId) {  
            this.refreshProfile();
        }
    }

    render() {
        
        //if (!(this.props.isAuth && this.props.token)) return <Navigate to='/login/' />;

        return (
            <Profile {...this.props} 
                isOwner={!this.props.params.userId}
                profile={this.props.profile} 
                status={this.props.status} 
                updateStatus={this.props.updateStatus}
                savePhoto={this.props.savePhoto} 
            />
        )
    }
}

let mapStateToProps = (state) => ({
    profile: state.profilePage.profile,
    status: state.profilePage.status,
    authorizedUserId: state.auth.userId,
    //isAuth: state.auth.isAuth
});

export default compose (
    connect(mapStateToProps, {getUserProfile, getStatus, updateStatus, savePhoto, saveProfile}),
    withParams,
    withAuthRedirect
)(ProfileContainer);

//let AuthRedirectComponent = withAuthRedirect(ProfileContainer);
//let WidthUrlDataContainerComponent = withRouter(AuthRedirectComponent); 

//export default connect(mapStateToProps, {getUserProfile})(WidthUrlDataContainerComponent);
