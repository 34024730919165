import { blogAPI } from "../api/api";

const SET_POST_PAGE = 'SET_POST_PAGE';
const TOGGLE_POST_IS_FETCHIG = 'TOGGLE_POST_IS_FETCHIG';

let initialState = {
    post: [] as Array<string>,
    params: [] as Array<string>,
    isFetching: false as boolean
};
export type InitialStateType = typeof initialState;

const blogPostReducer = (state = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case SET_POST_PAGE: {
            return { ...state, post: action.post };
        }
        case TOGGLE_POST_IS_FETCHIG: {
            return { ...state, isFetching: action.isFetching };
        }
        default:
            return state;
    }
};
export const setPost = (post: Array<string>) => ({ type: SET_POST_PAGE, post });
export const toggleIsFetching = (isFetching: boolean) => ({ type: TOGGLE_POST_IS_FETCHIG, isFetching });

export const requestPost = (postId: number) => async (dispatch: any) => {
    dispatch( toggleIsFetching(true) );
    const response = await blogAPI.getPost(postId);    
    dispatch( setPost(response) );
    dispatch( toggleIsFetching(false) );
}

export default blogPostReducer;
