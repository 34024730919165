import React, { Suspense, lazy } from "react";
import s from "./Homepage.module.css";
import Preloader from "../common/Preloader/Preloader";
//import { connect } from 'react-redux';
//import Services from "../Services/Services";
//import StoreContext from '../../StoreContext';
//import ServicesContainer from "../Services/ServicesContainer";
const ServicesContainer = lazy(() => import('../Services/ServicesContainer'));

/*let mapStateToProps = (state) => {
  return { 
    data: state.servicesPage
  }
}
const ServicesBlocks = connect(mapStateToProps)(Services);
*/
const Homepage = (props) => {
  
  return (
    <div className={s.home}>

        <section className={s.banner}>
            <div className="container">
                <h1>Разработка сайтов на базе Webasyst</h1>
                <p>Веб-студия специализируется на разработке интернет-ресурсов на платформе
                Webasyst. Молодая команда с большим опытом работы в сфере дизайна и
                программирования. Выбрав нас вы получите тот продукт, который будет
                уникальным и принесет действительно нужных вам клиентов за разумные
                деньги. Мы уделяем особое внимание юзабилити и функциональности.
                </p>
            </div>    
        </section>
        <Suspense fallback={<Preloader />}>
            <ServicesContainer />
        </Suspense>
        
        {/*
        <StoreContext.Consumer> 
        {
            (store) => <Services data={store.getState().servicesPage} />
        }
        </StoreContext.Consumer> 
        */}
 
    </div>
  );
};




export default Homepage;
