import { stopSubmit } from "redux-form";
import { profileAPI } from "../api/api";
import { PhotosType, PostType, ProfileType } from "../types/types";

const ADD_POST = 'ADD-POST';
const UPDATE_NEW_POST_TEXT = 'UPDATE-NEW-POST-TEXT';
const SET_USER_PROFILE = 'SET_USER_PROFILE';
const SET_STATUS = 'SET_STATUS';
const SAVE_PHOTO_SUCCESS = 'SAVE_PHOTO_SUCCESS';


let initialState = {
    posts: [
        {id: 1, message: 'Hi, how are you?', likesCount: 12},
        {id: 2, message: 'It\'s my first post', likesCount: 11},
        {id: 3, message: 'Blabla', likesCount: 11},
        {id: 4, message: 'Dada', likesCount: 11}
    ] as Array<PostType>,
    newPostText: 'modern-app',
    profile: null as ProfileType | null,
    status: ''
};
export type initialStateType = typeof initialState;

const profileReducer = (state = initialState, action: any): initialStateType => {

    switch(action.type) {
        case ADD_POST: {
            let newPost = {
                id: 5,
                message: state.newPostText,
                likesCount: 0
            };
            return {
                ...state,
                posts: [...state.posts, newPost],
                newPostText: ''
            };
        }
        case UPDATE_NEW_POST_TEXT: {
            return {
                ...state,
                newPostText: action.newText
            }
        }
        case SET_STATUS: {
            return {
                ...state,
                status: action.status
            }
        }
        case SAVE_PHOTO_SUCCESS: {
            return {
                ...state,
                profile: {...state.profile, photos: action.photos} as ProfileType
            }
        }
        case SET_USER_PROFILE: {
            return {...state, profile: action.profile}
        }
        default:
            return state;
    }
}

type AddPostActionCreatorActionType = {
    type: typeof ADD_POST
    newPostText: string
}
export const addPostActionCreator = (newPostText: string): AddPostActionCreatorActionType => ({type: ADD_POST, newPostText})
type SetUserProfileActionType = {
    type: typeof SET_USER_PROFILE
    profile: ProfileType
}
export const setUserProfile = (profile: ProfileType): SetUserProfileActionType => ({type: SET_USER_PROFILE, profile})
type SetStatusActionType = {
    type: typeof SET_STATUS
    status: string
}
export const setStatus = (status: string): SetStatusActionType => ({type: SET_STATUS, status})
type UpdateNewPostTextActionCreatorActionType = {
    type: typeof UPDATE_NEW_POST_TEXT
    newText: string
}
export const updateNewPostTextActionCreator = (text: string): UpdateNewPostTextActionCreatorActionType => ({
    type: UPDATE_NEW_POST_TEXT, newText: text })
type SavePhotoSucccessActionType = {
    type: typeof SAVE_PHOTO_SUCCESS
    photos: PhotosType
}    
export const savePhotoSucccess = (photos: PhotosType): SavePhotoSucccessActionType => ({type: SAVE_PHOTO_SUCCESS, photos})


export const getUserProfile = (userId: number) => (dispatch: any) => {
    profileAPI.getProfile(userId).then(response => {
        dispatch(setUserProfile(response.data));
    });
}
export const getStatus = (userId: number) => (dispatch: any) => {
    profileAPI.getStatus(userId).then(response => {
        dispatch(setStatus(response.data));
    });
}
export const updateStatus = (status: string) => async (dispatch: any) => {
    try {
        const response = await profileAPI.updateStatus(status);
        if (response.data.resultCode === 0) {
            dispatch(setStatus(status)); 
        }
    } catch(error) {
        //
    }
    
}
export const savePhoto = (file: any) => (dispatch: any) => {
    profileAPI.savePhoto(file)
    .then(response => {
        if (response.data.resultCode === 0) {
            dispatch(savePhotoSucccess(response.data.data.photos));
        }        
    });
}
const getErrorsFromMessages = (messages: Array<string>) => {
    let errors = Object.keys(messages).reduce((acc: any, key: any) => {
        if (messages[key].includes("->")) {
            let errorMessage: any = messages[key].split("->");
            errorMessage = errorMessage[1]
            .slice(0, errorMessage[1].length - 1)
            .toLowerCase();
            return { ...acc, [errorMessage]: messages[key] };
        }
    }, {});
    return errors;
};
export const saveProfile = (profile: ProfileType) => async (dispatch: any, getState: any) => {
    const userId = getState().auth.userId;
    const response = await profileAPI.saveProfile(profile);
    
    if (response.data.resultCode === 0) {
        dispatch(getUserProfile(userId));
    } else {
        dispatch(stopSubmit("ProfileForm", { 
            _error: response.data.messages.join(", "), 
            contacts: getErrorsFromMessages(response.data.messages)
        }));
        return Promise.reject(response.data.messages.join(", "));
    }        
    
}
export default profileReducer;