import React from "react";
import s from "../Services.module.css";
//import { NavLink } from 'react-router-dom'; 
import { Outlet, Link } from "react-router-dom";
import Price from "../../common/Price/Price";

const ServiceItem = (props) => {
  
    let path = "/services/"+ props.id +"/";
    return (
        <div className="col s12 m6 l4">
        <div className={s.cont}>
            <div className={s.block}>
            <i className={`${s.icon} ${props.icon ? props.icon : ''}`}></i>
            <h2>{props.name}</h2>
            <p>{props.description}</p>
            <div className={s.offers}>

                <Price price={props.price} />
                
            </div>
            </div>
            <Link to={path}>{props.name}</Link>
        </div>
        </div>
    );
};

export default ServiceItem;
