import React, { useState } from 'react';
import style from './ProfileInfo.module.css';
import Preloader from "../../common/Preloader/Preloader";
//import Icon from "./../../common/IconsSvg/IconsSvg";
import { FiCamera } from "react-icons/fi";
import ProfileStatus from "./ProfileStatusWithHooks";
import { SocialIcon } from 'react-social-icons';
import ProfileDataForm from './ProfileDataForm';


const ProfileInfo = ({profile, isOwner, savePhoto, status, updateStatus, saveProfile}) => {
    let [editMode, setEditMode] = useState(false);

    if (!profile) {
        return <Preloader />
    }
    const onMainPhotoSelected = (e) => {
        if (e.target.files.length) {
            savePhoto(e.target.files[0]);
        }
    }
    const onSubmit = (formData) => {
        saveProfile(formData).then(
            () => {
                setEditMode(false);
            }
        ); 
    }

    return (
        <>
        <section className={style.profile}>
            <div className="container">
                <div className='row flex wrap'>
                    <div className='col'>
                        <div className={style.user_image}>
                            {isOwner && 
                            <label htmlFor="avatar_upload" className={style.upload_lable}><FiCamera size="4em" /></label>}
                            <img 
                                src={ profile.photos.large != null 
                                    ? profile.photos.large 
                                    : '/images/user_100x100@2x.jpg' }
                                loading='lazy'
                            /> 
                              
                        </div>
                        {isOwner && 
                        <input className={style.upload_file_input} id="avatar_upload" type="file" onChange={ onMainPhotoSelected } />}
                    </div>
                    <div className={`col ${style.description}`}>
                        {isOwner 
                            ? <ProfileStatus status={status} updateStatus={updateStatus} />
                            : status
                        }
                        <p className={style.name}>{profile.fullName}</p>
                    </div>
                </div>  
            </div>
        </section>
        <section className="container">
            <div className={style.description}>
                {editMode 
                    ? <ProfileDataForm initialValues={profile} contacts={profile.contacts} onSubmit={onSubmit} /> 
                    : <ProfileData goToEditMode={() => setEditMode(true)} profile={profile} isOwner={isOwner} />
                }
            </div>
        </section>       
        </>
        
    )
}
const ProfileData = ({profile, isOwner, goToEditMode}) => {
    return (
        <div>
            {isOwner &&
                <div className='input-field'>
                    <button onClick={goToEditMode}>Edit</button>
                </div>
            }
            <div className='input-field'>
                About me: {profile.aboutMe} 
            </div>
            <div className='input-field'>
                Looking for a job: {profile.lookingForAJob ? 'yes' : 'no'} 
            </div>
            {profile.lookingForAJob &&
                <div className='input-field'>
                    My professional skills: {profile.lookingForAJobDescription} 
                </div>
            }
            <div className='input-field'>
                <strong>Contacts: </strong>
                <div className='flex wrap row input-field'>
                {Object.keys(profile.contacts).map(key => {
                    return <ContactItem key={key} contactTitle={key} contactValue={profile.contacts[key]} />
                })}  
                </div>    
            </div>
        </div>
    )
}

const ContactItem = ({contactTitle, contactValue}) => {
    return (
        <>
        {contactValue &&
        <div className="col">
            {contactTitle !== 'website' && contactTitle !== 'mainLink' &&
            <SocialIcon network={contactTitle} style={{ height: 32, width: 32 }} title={contactTitle} />} {contactValue}
        </div>
        }
        </>        
    )
}
export default ProfileInfo;