import React from 'react';
import { Link } from "react-router-dom";
import s from "./../Header/Header.module.css";
import styled from "styled-components";
import { FiUser } from "react-icons/fi";

const LoginBlock = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 0 1rem;
    color: #fff;
`;
const LoginLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    gap: 10px;
    color: inherit;
    font-size: 0.875em;
`;

const LoginBar = ( { photos, ...props } ) => {
    
    return (
        <LoginBlock>
            {props.isAuth 
                ? ( <>
                        <LoginLink to="/login/"> 
                            <img className={s.userpic} 
                                src={ photos && photos.small !== null ? photos.small : '/images/user_100x100@2x.jpg' }
                                width="32" height="32" loading='lazy' /> {props.nameLogin}
                        </LoginLink> 
                        <button onClick={ props.logoutToSamurai }>Logout</button>
                    </>
                    ): props.token && props.name ? (
                        <>
                            <LoginLink to="/login/">
                                <img className={s.userpic} src={props.userpic} width="32" height="32" /> {props.name}
                            </LoginLink>
                            <button onClick={ () => props.logOut() }>Logout</button>
                        </>
                    ) : <LoginLink to="/login/">
                            <div className={s.userpic}><FiUser/></div> login
                        </LoginLink>
                }
        </LoginBlock>    
    )
}

export default LoginBar;