import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(NavLink)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    height: 60px;
    padding: 0 .5rem;
    font-size: 0.875em;
    font-weight: 500;
    text-transform: uppercase;

    &.active {
        color: #ff4848;
    }
`;
const SidebarLabel= styled.span`
    margin-left: .5rem;
`;

const NavMenu = ({ item }) => {

    return (

        <SidebarLink to={item.path} >
            <div>
                {/*item.icon*/}
                <SidebarLabel>
                    {item.title}
                </SidebarLabel>
            </div>  
        </SidebarLink>

    )
}

export default NavMenu;
