import { pagesAPI } from "../api/api";

const SET_SERVICES_PAGES = 'SET_SERVICES_PAGES';
const TOGGLE_IS_FETCHIG = 'TOGGLE_IS_FETCHIG';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

type ServicesType = {
    id: number
    parent_id: null | string
    name: string
    title: string
    full_url: string
    url: string
    route: string
    create_datetime: string
    update_datetime: string
    status: number
    childs: any
}
let initialState = {
    services: [] as Array<ServicesType>,
    errorMessage: '',
    isFetching: false
};
type initialStateType = typeof initialState;

const servicesReducer = (state = initialState, action: any): initialStateType  => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
    case SET_SERVICES_PAGES: {
        return {
            ...state,
            ...action.payload
        }
        //return { ...state, services: action.services };
        //return { ...state, services: action.services.filter(item => item.status === '1') };
    }
    case TOGGLE_IS_FETCHIG: {
        return { ...state, isFetching: action.isFetching };
    }
    default:
        return state;
  }
};
export const setServices = (services: Array<ServicesType>) => ({ type: SET_SERVICES_PAGES, payload: {services: services} });
export const toggleIsFetching = (isFetching: boolean) => ({ type: TOGGLE_IS_FETCHIG, isFetching });
export const setErrorMessage = (message: string) => ({ type: SET_ERROR_MESSAGE, payload: {errorMessage: message} });

// замыкание / санки
export const requestServices = () => async (dispatch: any) => {
    dispatch( toggleIsFetching(true) );
    try {
        const response = await pagesAPI.getServices();
        dispatch( toggleIsFetching(false) );
        dispatch( setServices(response) );
    } catch(error: any) {
        dispatch( toggleIsFetching(false) );
        dispatch( setErrorMessage(error.message) );
        //alert(error.message)
    }

    /*pagesAPI.getServices().then(response => {
        console.log(response)
        dispatch( toggleIsFetching(false) );
        dispatch( setServices(response) );         
    })*/

}

export default servicesReducer;
